// src/components/Services.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

const Services = () => {
  const navigate = useNavigate();

  const handleCardClick = (path) => {
    navigate(path); // Navigate to the path of the clicked service
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
  };

  const services = [
    {
      img: '/images/desk/seo_2.jpeg',
      title: 'SEO',
      description: 'Improve your website’s visibility through targeted keyword optimization, on-page SEO strategies, technical SEO audits, and link-building efforts. This holistic approach ensures that your website ranks higher in search results, driving more organic traffic.',
      path: '/seo-optimization'
    },
    {
      img: '/images/desk/Cmm_1.jpeg',
      title: 'Content Marketing',
      description: 'Create valuable content that not only attracts but also engages your target audience. We develop a range of content formats, including blogs, articles, infographics, and videos, to position your brand as an industry authority.',
      path: '/content-marketing'
    },
    {
      img: '/images/desk/Smm_2.jpeg',
      title: 'Social Media Marketing (SMM)',
      description: 'Leverage platforms like Facebook, Instagram, LinkedIn, and Twitter to build brand awareness and engage with customers through strategic campaigns tailored to your audience’s preferences.',
      path: '/social-media-marketing'
    },
    {
      
      title: 'Pay-Per-Click (PPC) Advertising',
      description: 'Drive targeted traffic to your website through effective Google Ads and social media advertising campaigns. Our focus on measurable results allows you to track your ROI effectively.',
      img: '/images/desk/payperclick_2.jpeg',
      path: '/google-advertising' // Add the path for routing
    },
    {
      
      title: 'Email Marketing',
      description: 'Develop personalized email campaigns that nurture leads and convert them into customers. We craft engaging content that keeps your audience informed and interested in your offerings.',
      img: '/images/desk/email_m_1.jpeg',
      path: '/email-marketing' // Add the path for routing
    },
    {
      title: 'Influencer Marketing',
      img: '/images/desk/influ_2.jpeg',      
      description: 'Collaborate with influencers in your industry to tap into new audiences and enhance your brand’s credibility through authentic endorsements.',
      path: '/influencer-marketing' // Add the path for routing
    },
    {
      title: 'Analytics & Reporting',
      img: '/images/desk/Analytics_1.jpeg',      
      description: 'Utilize data analytics tools to track performance metrics across all marketing channels. We provide insights that help optimize your strategies for better results.',
      path: '/analytics-and-reporting' // Add the path for routing
    },
    {
      title: 'Brand Strategy Development',
      img: '/images/desk/b&d_1.jpeg',  
      description: 'Assist businesses in defining their brand identity and developing strategies that resonate with their target audience, ensuring consistency across all marketing efforts.',
      path: '/brand-strategy-development' // Add the path for routing
    }
  ];

  return (
    <div className="p-8 md:px-16 lg:px-24" style={{ background: 'radial-gradient(circle, #d3d3d3 30%, #deeaee 70%)' }}>
    
      <h2 className="text-3xl font-bold mb-4 text-center">Digital Marketing Services</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {services.map((service, index) => (
          <motion.div
            key={index}
            className="relative bg-gray-100 p-6 rounded-lg shadow-lg overflow-hidden cursor-pointer"
            variants={cardVariants}
            initial="hidden"
            animate="visible"
            whileHover={{ scale: 1.05, rotate: 2 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => handleCardClick(service.path)}
          >
            <motion.img
              src={service.img}
              alt={service.title}
              className="relative w-full h-30 object-cover rounded-t-lg"
              initial={{ y: -20 }}
              animate={{ y: 0 }}
              transition={{ duration: 0.5 }}
            />
            <h3 className="text-xl font-semibold mt-4">{service.title}</h3>
            <p>{service.description}</p>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Services;
