import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FcBusinessman } from "react-icons/fc";
import { FaBusinessTime } from "react-icons/fa6";
// import { FaFighterJet  } from 'react-icons/fa';
import './WebAppDevelopmentShowcase.css';

const About = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  const handleButtonClick = () => {
    navigate('/contact'); // Navigate to the contact page
  };

  return (
    <div className="showcase-container">
      {/* Meta Description for SEO */}
      <meta name="description" content="Custom web and app development services tailored to enhance your business performance. Discover our expertise in creating responsive websites, mobile apps, e-commerce solutions, and more. Contact us for a consultation!" />
      
      {/* Hero Section */}
      <div className="hero-section"><br></br><br></br>
        <h1>About us</h1>
        <p>
          At Next Leap Tech, we specialize in Web & App Development that empowers businesses to thrive in the digital landscape. Our expert team combines creativity with technical expertise to deliver solutions tailored to your unique needs.
        </p><br>
        </br>
        <div className="consultation-container"><br></br>
        {/* <FaFighterJet  className="plane-icon" /> */}
    
        </div>
    
        <button className="cta-button" onClick={handleButtonClick}><FaBusinessTime />Get Your Free Consultation </button> {/* Updated button */}
       </div>

      {/* Services Section */}
      <div className="services-section">
      
        <h2 className="text-3xl font-bold mb-4 grid place-items-center  bg-gray-100">About</h2>
        {/* <h2>About</h2> className="service-card "*/}
        <div >
        <div className="flex justify-center items-center">
  <div className="w-full h-auto md:w-3/4 lg:w-1/2 xl:w-1/3 p-4">
    <img src="/images/NLT_LOGO.png" alt="Custom Web Development" className="mx-auto" />
  </div>
</div>

            <div className="about-us-section">
              {/* <h2 className="text-1xl  font-bold mb-1 place-items-center">About US : </h2><br></br> */}
              <br>
            
              </br>
              <p>
                We create tailored websites that reflect your brand identity and engage users effectively. Our websites are designed with user experience (UX) in mind, ensuring seamless navigation and responsiveness across all devices, including desktops, tablets, and smartphones.
              </p>
            </div>
         

          {/* <div className="service-card right">
          <img className='mobile_view' src="https://picsum.photos/500/300?random=2" alt="Mobile App Development" />
            <div className="service-card-text">
              <h3 className="text-1xl font-bold mb-1">Mobile App Development</h3><br></br>
              <p>
                Our user-friendly applications are crafted for both iOS and Android platforms, providing a consistent experience that meets the expectations of today’s mobile users. We utilize the latest technologies to ensure your app is not only functional but also visually appealing.
              </p>
            </div>
            <img className='desktop_view' src="https://picsum.photos/500/300?random=2" alt="Mobile App Development" />
          </div> */}

          {/* <div className="service-card left">
            <img src="https://picsum.photos/500/300?random=3" alt="E-Commerce Solutions" />
            <div className="service-card-text">
              <h3 className="text-1xl font-bold mb-1">Progressive Web Apps (PWAs)</h3><br></br>
              <p>
                Combining the best of web and mobile apps, our PWAs offer offline capabilities and fast loading times, ensuring users have a smooth experience regardless of their internet connectivity.
              </p>
            </div>
          </div> */}
        </div>
      </div>

      {/* Why Choose Us Section */}
      <div className="about-us-section">
        <h2>Why Choose Us?   <FcBusinessman /></h2>
        <p>
          <strong>User-Centric Design:</strong> Our designs prioritize user experience, ensuring intuitive interfaces that enhance engagement and conversion rates. We conduct thorough research to understand your target audience's needs and preferences.
        </p> 
        <p>
          <strong>SEO-Friendly Architecture:</strong> All our websites are built with search engine optimization in mind. We implement best practices that help improve visibility on search engines, driving organic traffic to your site.
        </p> 
        <p>
          <strong>Ongoing Support:</strong> Our commitment doesn’t end at launch; we provide continuous support and maintenance to ensure your digital assets perform optimally. Whether you need updates or troubleshooting, our team is here for you.
        </p>  
      </div>

      {/* CTA Section */}
      <div className="cta-section">
        <p>
          Ready to elevate your digital strategy? <a href="/contact" className="cta-link">Contact us today</a> to discuss how we can help you achieve your business goals with our expert web and app development services.
        </p>
      </div>
    </div>
  );
};

export default About;
