import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaBusinessTime } from "react-icons/fa6";
import { FcBusinessman } from "react-icons/fc";
import './WebAppDevelopmentShowcase.css';
// import './SaaSPage.css';


const Socialmediamarketing = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  const handleButtonClick = () => {
    navigate('/contact'); // Navigate to the contact page
  };

  return (
    
    <div className="showcase-container">
      {/* Meta Description for SEO */}
      <meta name="description" content="Connect with your audience through our Social Media Marketing services. Contact us today for a social media strategy consultation!" />
      
      {/* Hero Section */}
      <div className="hero-section">
        <h1>Social Media Marketing (SMM) - Amplify Your Brand’s Voice</h1>
        <p>
          At Next Leap Tech, we understand the importance of connecting brands directly with their audience through effective Social Media Marketing (SMM) strategies tailored specifically towards enhancing engagement levels.
        </p><br>
        </br>
        <button className="cta-button" onClick={handleButtonClick}><FaBusinessTime />Get Your Free Consultation </button> {/* Updated button */}
       
      </div>

      {/* Services Section */}
      <div className="services-section">
      
        <h2 className="text-3xl font-bold mb-4 grid place-items-center  bg-gray-100">Our SMM Services Include:</h2>
        <div className="services-grid">
          <div className="service-card left">
            <img src="images/desk/smm_3.jpeg" alt="Content Creation" />
            <div className="service-card-text">
              <h3 className="text-1xl font-bold mb-1">Content Creation</h3><br></br>
              <p>
                Engaging posts crafted specifically based on target audience preferences while incorporating relevant hashtags.
              </p>
            </div>
          </div>

          <div className="service-card right">
          <img className='mobile_view' src="images/desk/smm_4.jpeg" alt="Infographics Creation Development" />
            <div className="service-card-text">
              <h3 className="text-1xl font-bold mb-1">Infographics Creation</h3><br></br>
              <p>
                Visually appealing infographics that simplify complex information while encouraging shares across social media platforms.
              </p>
            </div>
            <img className='desktop_view' src="images/desk/smm_4.jpeg" alt="Infographics Creation Development" />
          </div>

          <div className="service-card left">
            <img src="images/desk/smm_5.jpeg" alt="Social Media Management Solutions" />
            <div className="service-card-text">
              <h3 className="text-1xl font-bold mb-1">Social Media Management</h3><br></br>
              <p>
                Comprehensive management across platforms including Facebook, Instagram Twitter etc., ensuring consistent messaging aligned with brand values.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Why Choose Us Section */}
      <div className="about-us-section">
      <h2>Why Choose Us?   <FcBusinessman /></h2>
        <p>
        <strong>Proven Track Record:</strong> With successful campaigns under our belt—we know what it takes to make brands stand out online!
        </p>
        <p>
        <strong>Analytics-Driven Approach:</strong> Regular monitoring allows adjustments based on real-time feedback from followers.
        </p>
        <p>
        <strong>Expert Support:</strong> Our dedicated team is here to assist you at every step of the way, providing ongoing support and maintenance as needed.
        </p>
      </div>

      <div className="about-us-section">
        <h2 className="text-1xl font-bold mb-1">Client Success Stories</h2><br></br>
        <p>"Our social media presence has grown exponentially since partnering up! The team at Next Leap Tech truly knows how to create compelling content."<br></br>
            — Chandan, Ashraya Solutions
        </p>
        </div>

      {/* CTA Section */}
      <div className="cta-section">
        <h2 className="text-1xl font-bold mb-1">Get Started Today!</h2><br></br>
        <p>
            Amplify your brand’s voice through social media! <a href="/contact" className="cta-link">Contact us today</a> to discuss how we can help you achieve your business goals with our experts.
        </p>
      </div>
    </div>
  );
};

export default Socialmediamarketing;
