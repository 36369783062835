import React from 'react';
import { useNavigate } from 'react-router-dom';
import './WebAppDevelopmentShowcase.css';

const SeoOptimization = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  const handleButtonClick = () => {
    navigate('/contact'); // Navigate to the contact page
  };

  return (
    <div className="showcase-container">
      {/* Meta Description for SEO */}
      <meta name="description" content="Enhance your online visibility with our expert SEO services. Contact us today for a free SEO audit!" />
      
      {/* Hero Section */}
      <div className="hero-section">
        <h1>Search Engine Optimization (SEO): Improve Your Search Rankings</h1>
        <p>
          At Next Leap Tech, we offer comprehensive Search Engine Optimization (SEO) services designed to improve your website’s visibility on search engines like Google. Our strategies focus on driving organic traffic and increasing conversions through targeted optimization techniques.
        </p><br>
        </br>
        <button className="cta-button" onClick={handleButtonClick}>Get Your Free Consultation</button> {/* Updated button */}
      </div>

      {/* Services Section */}
      <div className="services-section">
      
        <h2 className="text-3xl font-bold mb-4 grid place-items-center  bg-gray-100">Our SEO Services Include</h2>
        <div className="services-grid">
          <div className="service-card left">
            <img src="images/desk/seo_!.jpeg" alt="Custom Web Development" />
            <div className="service-card-text">
              <h3 className="text-1xl font-bold mb-1">Keyword Research & Strategy</h3><br></br>
              <p>
                We conduct thorough keyword research to identify high-value keywords relevant to your business. This includes commercial intent keywords that attract buyers ready to make a purchase.
              </p>
            </div>
          </div>

          <div className="service-card right">
          <img className='mobile_view' src="images/desk/seo_5.jpeg" alt="On-page Optimization" />
            <div className="service-card-text">
              <h3 className="text-1xl font-bold mb-1">On-page Optimization</h3><br></br>
              <p>
                Optimize title tags, meta descriptions, headers, and content throughout the site using identified keywords while maintaining natural readability.
              </p>
            </div>
            <img className='desktop_view' src="images/desk/seo_5.jpeg" alt="On-page Optimization" />
          </div>

          <div className="service-card left">
            <img src="images/desk/seo_6.jpeg" alt="Link Building Strategies" />
            <div className="service-card-text">
              <h3 className="text-1xl font-bold mb-1">Link Building Strategies</h3><br></br>
              <p>
                Establish authority through ethical link-building practices that enhance domain authority and improve search rankings over time.
              </p>
            </div>
          </div>
  
          <div className="service-card right">
          <img className='mobile_view' src="images/desk/seo_4.jpeg" alt="Local SEO Services" />
            <div className="service-card-text">
              <h3 className="text-1xl font-bold mb-1">Local SEO Services</h3><br></br>
              <p>
                Optimize your online presence for local searches by implementing strategies such as Google My Business optimization and local keyword targeting.
              </p>
            </div>
            <img className='desktop_view' src="images/desk/seo_4.jpeg" alt="Local SEO Services" />
          </div>
        </div>
      </div>

      {/* Why Choose Us Section */}
      <div className="about-us-section">
        <h2>Why Choose Us?</h2>
        <p>
        <strong>Proven Strategies:</strong> We use data-driven approaches tailored specifically for each client’s unique market position.
        </p>
        <p>
        <strong>Continuous Monitoring & Reporting:</strong> Regular performance reports keep you informed about progress while allowing adjustments based on analytics insights.
        </p>
        <p>
        <strong>Expert Support:</strong> Our dedicated team is here to assist you at every step of the way, providing ongoing support and maintenance as needed.
        </p>
      </div>

      <div className="about-us-section">
        <h2 className="text-1xl font-bold mb-1">Client Success Stories</h2><br></br>
        <p>The SEO strategies implemented by Next Leap Tech have significantly improved our website traffic!<br></br>
            — Chandan  , Ashraya Solutions
        </p>
        </div>

      {/* CTA Section */}
      <div className="cta-section">
        <h2 className="text-1xl font-bold mb-1">Get Started Today!</h2><br></br>
        <p>
            Enhance your online presence with our expert SEO services! <a href="/contact" className="cta-link">Contact us today</a> to discuss how we can help you achieve your business goals with our experts.
        </p>
      </div>
    </div>
  );
};

export default SeoOptimization;
