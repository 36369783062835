// TermsAndConditionsPage.js
import React from 'react';
import { FaUserCheck, FaFileContract, FaExclamationCircle, FaGavel, FaRegClock, FaBalanceScale } from 'react-icons/fa';

const TermsAndConditionsPage = () => {
  return (
    <div style={{
      maxWidth: '900px',
      margin: 'auto',
      padding: '60px 30px',
      fontFamily: "'Roboto', sans-serif",
      lineHeight: '1.8',
      color: '#2d3436',
      backgroundColor: '#f9f9f9',
      boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
      borderRadius: '8px'
    }}>
      <h1 style={{
        fontSize: '40px',
        fontWeight: '700',
        textAlign: 'center',
        marginBottom: '20px',
        color: '#0984e3',
        textShadow: '1px 1px 1px rgba(0,0,0,0.1)'
      }}>
        Terms and Conditions
      </h1>
      
      <p style={{ fontSize: '18px', textAlign: 'center', color: '#636e72' }}>
        Welcome to NextLeapTeach! Please read these terms and conditions carefully as they outline your rights and obligations.
      </p>

      <div style={{ marginTop: '40px' }}>
        <h2 style={{ fontSize: '24px', color: '#6c5ce7', display: 'flex', alignItems: 'center' }}>
          <FaUserCheck style={{ marginRight: '10px' }} /> 1. Acceptance of Terms
        </h2>
        <p>By using our website, you agree to these terms and conditions. If you do not agree, please discontinue use of our site.</p>
      </div>

      <div style={{ marginTop: '30px' }}>
        <h2 style={{ fontSize: '24px', color: '#6c5ce7', display: 'flex', alignItems: 'center' }}>
          <FaFileContract style={{ marginRight: '10px' }} /> 2. Modifications to Terms
        </h2>
        <p>We reserve the right to modify these terms at any time. Any changes will be posted on this page, and continued use signifies acceptance.</p>
      </div>

      <div style={{ marginTop: '30px' }}>
        <h2 style={{ fontSize: '24px', color: '#6c5ce7', display: 'flex', alignItems: 'center' }}>
          <FaExclamationCircle style={{ marginRight: '10px' }} /> 3. User Responsibilities
        </h2>
        <p>Users agree to use our website responsibly, avoiding prohibited actions, including but not limited to:</p>
        <ul style={{ paddingLeft: '20px', color: '#636e72' }}>
          <li>Illegal activities or misuse of the website's content.</li>
          <li>Attempts to disrupt website functionality.</li>
          <li>Unauthorized data access or collection.</li>
        </ul>
      </div>

      <div style={{ marginTop: '30px' }}>
        <h2 style={{ fontSize: '24px', color: '#6c5ce7', display: 'flex', alignItems: 'center' }}>
          <FaGavel style={{ marginRight: '10px' }} /> 4. Intellectual Property
        </h2>
        <p>All content on our website, including text, graphics, and code, is our intellectual property or that of our licensors. Unauthorized use is prohibited.</p>
      </div>

      <div style={{ marginTop: '30px' }}>
        <h2 style={{ fontSize: '24px', color: '#6c5ce7', display: 'flex', alignItems: 'center' }}>
          <FaBalanceScale style={{ marginRight: '10px' }} /> 5. Limitation of Liability
        </h2>
        <p>We are not liable for any direct, indirect, or consequential damages arising from the use or inability to use our website or services.</p>
      </div>

      <div style={{ marginTop: '30px' }}>
        <h2 style={{ fontSize: '24px', color: '#6c5ce7', display: 'flex', alignItems: 'center' }}>
          <FaRegClock style={{ marginRight: '10px' }} /> 6. Governing Law
        </h2>
        <p>These terms and any disputes will be governed by and construed in accordance with the laws of [Your Country/State].</p>
      </div>

      <p style={{ fontSize: '16px', textAlign: 'center', marginTop: '50px', color: '#b2bec3' }}>Last updated: 28-10-2024 Monday 12:30pm(IST)</p>
    </div>
  );
};

export default TermsAndConditionsPage;
