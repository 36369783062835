import React from 'react';
import { motion } from 'framer-motion';

const testimonials = [
  {
    text: "Amazing work! The website looks fantastic and has helped increase our traffic.",
    author: "Allen Michael",
    position: "NextLeap Tech client"
  },
  {
    text: "Professional and creative design that perfectly represents our brand.",
    author: "Benford Lee",
    position: "NextLeap Tech client"
  },
  {
    text: "A pleasure to work with, delivering results beyond our expectations.",
    author: "Catriona Claire",
    position: "NextLeap Tech client"
  }
];

const Testimonials = () => {
  return (
    <div className="p-6 bg-gray-200">
      <h2 className="text-3xl font-bold mb-8 text-center">What Our Clients Say</h2>
      <div className="flex flex-col md:flex-row md:space-x-6">
        {testimonials.map((testimonial, index) => (
          <motion.div
            key={index}
            className="bg-white p-6 rounded-lg shadow-lg mb-6 md:mb-0 flex-1"
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.7, delay: index * 0.3 }}
          >
            <p className="italic mb-4">"{testimonial.text}"</p>
            <h3 className="font-semibold">{testimonial.author}</h3>
            <p className="text-gray-600">{testimonial.position}</p>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
