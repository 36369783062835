// src/components/Header.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';

const Header = ({ showHeader }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  
  // if (!showHeader) return null;

  return (
    <header className="fixed top-0 left-0 w-full bg-black bg-opacity-30 z-30">
      <div className="container mx-auto px-4 py-4 flex justify-between items-center">
  <div className="flex items-center text-white font-bold text-2xl">
                NL Tech
  {/* <img src="/images/NLT_LOGO.png" alt="logo" className="w-10 h-auto ml-2"  onClick={<Link to="/"> </Link>}/> */}
  <Link to="/"> <img src="/images/NLT_LOGO.png" alt="logo" className="w-10 h-auto ml-2"/></Link>
</div>

        <nav className="hidden md:flex space-x-4">
          <Link to="/" className="text-white hover:text-gray-300">Home</Link>
          {/* <Link to="/blog">Blog</Link> */}
          <Link to="/about" className="text-white hover:text-gray-300">About</Link>
          <Link to="/Serviceall" className="text-white hover:text-gray-300">Services</Link>
          <Link to="/contact" className="text-white hover:text-gray-300">Contact</Link>
          <Link to="/Privacypage" className="text-white hover:text-gray-300">Privacypage</Link>
          <Link to="/terms-and-conditions" className="text-white hover:text-gray-300">Termsnconditions</Link>
        </nav>
        <div className="md:hidden flex items-center">
          <button onClick={toggleMenu} className="text-white focus:outline-none">
            {isOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
          </button>
        </div>
      </div>
      {isOpen && (
        <div className="md:hidden bg-black bg-opacity-90 text-white py-2">
          <Link to="/" className="block px-4 py-2 hover:bg-gray-700" onClick={toggleMenu}>Home</Link>
          <Link to="/about" className="block px-4 py-2 hover:bg-gray-700" onClick={toggleMenu}>About</Link>
          <Link to="/Serviceall" className="block px-4 py-2 hover:bg-gray-700" onClick={toggleMenu}>Services</Link>
          <Link to="/contact" className="block px-4 py-2 hover:bg-gray-700" onClick={toggleMenu}>Contact</Link>
          <Link to="/Privacypage" className="block px-4 py-2 hover:bg-gray-700" onClick={toggleMenu}>Privacypage</Link>
          <Link to="/terms-and-conditions" className="block px-4 py-2 hover:bg-gray-700" onClick={toggleMenu}>Termsnconditions</Link>
          
        </div>
      )}
    </header>
  );
};

export default Header;
