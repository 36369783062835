import React from 'react';
import { useNavigate } from 'react-router-dom';
import './WebAppDevelopmentShowcase.css';

const EcommerceStore = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  const handleButtonClick = () => {
    navigate('/contact'); // Navigate to the contact page
  };

  return (
    <div className="showcase-container">
      {/* Meta Description for SEO */}
      <meta name="description" content="Elevate your online store with our comprehensive E-commerce Solutions. Contact us today for a free consultation!" />
      
      {/* Hero Section */}
      <div className="hero-section">
        <h1>Best E-commerce Solutions: Boost Your Online Sales</h1>
        <p>
          At Next Leap Tech, we specialize in developing powerful E-commerce Solutions that not only attract customers but also convert visits into sales. Our tailored platforms enhance customer experience and drive revenue growth.
        </p><br>
        </br>
        <button className="cta-button" onClick={handleButtonClick}>Get Your Free Consultation</button> {/* Updated button */}
      </div>

      {/* Services Section */}
      <div className="services-section">
      
        <h2 className="text-3xl font-bold mb-4 grid place-items-center  bg-gray-100">Our E-commerce Services Include</h2>
        <div className="services-grid">
          <div className="service-card left">
            <img src="images/desk/ecommerce_8.jpeg" alt="Custom E-commerce Development" />
            <div className="service-card-text">
              <h3 className="text-1xl font-bold mb-1">Custom E-commerce Development</h3><br></br>
              <p>
                Tailored online stores designed specifically for your business needs. We ensure that every aspect of the store reflects your brand identity while providing an intuitive shopping experience.
              </p>
            </div>
          </div>

          <div className="service-card right">
          <img className='mobile_view' src="images/desk/ecommerce_11.jpeg" alt="Payment Gateway Integration" />
            <div className="service-card-text">
              <h3 className="text-1xl font-bold mb-1">Payment Gateway Integration</h3><br></br>
              <p>
                Secure payment solutions facilitate smooth transactions while ensuring customer trust. We integrate various payment options so customers can choose their preferred method.
              </p>
            </div>
            <img className='desktop_view' src="images/desk/ecommerce_11.jpeg" alt="Payment Gateway Integration" />
          </div>

          <div className="service-card left">
            <img src="images/desk/ecommerce_12.jpeg" alt="User Experience Optimization" />
            <div className="service-card-text">
              <h3 className="text-1xl font-bold mb-1">User Experience Optimization</h3><br></br>
              <p>
                Enhance customer satisfaction with intuitive navigation and design elements that guide users through their shopping journey seamlessly.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Why Choose Us Section */}
      <div className="about-us-section">
        <h2>Why Choose Us?</h2>
        <p>
          <strong>Proven Results:</strong> Our e-commerce solutions have helped numerous businesses increase their online sales through effective design and marketing strategies.
        </p> 
        <p>
          <strong>Mobile Optimization:</strong> All our e-commerce sites are fully responsive, ensuring a seamless shopping experience on any device—crucial in today's mobile-first world.
        </p> 
        <p>
          <strong>Ongoing Support & Maintenance:</strong> We’re here to support you even after launch, ensuring your store runs smoothly with regular updates and troubleshooting as needed.
        </p>  
      </div>

        <div className="about-us-section">
        <h2 className="text-1xl font-bold mb-1">Client Success Stories</h2><br></br>
        <p>Thanks to Next Leap Tech, our online sales have skyrocketed! Their e-commerce solutions are top-notch.<br></br>
            — Chandan  , Ashraya Solutions
        </p>
        </div>

      {/* CTA Section */}
      <div className="cta-section">
        <h2 className="text-1xl font-bold mb-1">Get Started Today!</h2><br></br>
        <p>
            Ready to boost your online sales? <a href="/contact" className="cta-link">Contact us today</a> to discuss how we can help you achieve your business goals with our experts.
        </p>
      </div>
    </div>
  );
};

export default EcommerceStore;
