
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';


import HomePage from './pages/HomePage';
import About from './pages/About';
import WebAppDevelopment from './pages/WebAppDevelopment';
import Contentmarketing from './pages/content-marketing';
import SaaSPage from './pages/SaasPage';
import Dataenigneering from './pages/Dataenigneering';
import BusinessBrandsWebsite from './pages/BusinessBrandsWebsite';
import EcommerceStore from './pages/EcommerceStore';
import SeoOptimization from './pages/SeoOptimization';
import MaintenancenSupport from './pages/MaintenancenSupport';
import Socialmediamarketing from './pages/social-media-marketing';
import Brandstrategydevelopment from './pages/brand-strategy-development';
import Googleadvertising from './pages/google-advertising';
import Emailmarketing from './pages/email-marketing';
import Seoservice from './pages/seo-service';
import Influencermarketing from './pages/influencer-marketing';
import Analyticsandreporting from './pages/analytics-and-reporting';
import Privacypage from './pages/Privacypage';
import Termsnconditions from './pages/Termsnconditions';
import NotFoundPage from './pages/NotFoundPage.js';

import ContactForm from './components/ContactForm';
import Services from './components/Services';
import Header from './components/Header';
import Footer from './components/Footer'; 
import Serviceall from './components/Serviceall';
import Blog from './components/Blog';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App = () => {
  return (
    <>
      <Header />
      <Routes>
      <Route path="/blog" element={<Blog />} />
        <Route path="/" element={<HomePage />} />
        
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<ContactForm />} />
        <Route path="/web-app-development" element={<WebAppDevelopment />} />
        <Route path="/SaasPage" element={<SaaSPage />} />
        <Route path="/data-engineering" element={<Dataenigneering />} />
        <Route path="/business-brands-website" element={<BusinessBrandsWebsite />} />
        <Route path="/ecommerce-store" element={<EcommerceStore />} />
        <Route path="/seo-optimization" element={<SeoOptimization />} />
        <Route path="/maintenancen-support" element={<MaintenancenSupport />} />
        <Route path="/services" element={<Services />} />
        <Route path="/serviceall" element={<Serviceall />} />
        <Route path="/content-marketing" element={<Contentmarketing />} />
        <Route path="/social-media-marketing" element={<Socialmediamarketing />} />
        <Route path="/brand-strategy-development" element={<Brandstrategydevelopment />} />
        <Route path="/google-advertising" element={<Googleadvertising />} />
        <Route path="/email-marketing" element={<Emailmarketing />} />
        <Route path="/influencer-marketing" element={<Influencermarketing />} />
        <Route path="/seo-service" element={<Seoservice />} />
        <Route path="/analytics-and-reporting" element={<Analyticsandreporting />} />
        <Route path="/Privacypage" element={<Privacypage />} />
        <Route path="/terms-and-conditions" element={<Termsnconditions />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <Footer />
    </>
  );
};

const AppWrapper = () => (
  <Router>
    <ScrollToTop />
    <App />
  </Router>
);

export default AppWrapper;
