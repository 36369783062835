import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FcBusinessman } from "react-icons/fc";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const [formErrors, setFormErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Clear errors on change
    setFormErrors({ ...formErrors, [name]: '' });
  };

  const validateForm = () => {
    const errors = {};

    if (!formData.name.trim()) {
      errors.name = 'Name is required';
    }

    if (!formData.email) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Email is invalid';
    }

    if (!formData.subject.trim()) {
      errors.subject = 'Subject is required';
    }

    if (!formData.message.trim()) {
      errors.message = 'Message is required';
    }

    return errors;
  };

  const handleSubmit =  (e) => {
    e.preventDefault();
    const errors = validateForm();
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      setIsSubmitting(true);

      try {
        // Send form data to backend
        const response =  fetch('https://nextleaptech.com/send', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });

        setIsSubmitted(true);
          setFormData({
            name: '',
            email: '',
            subject: '',
            message: '',
          });
          setTimeout(() => setIsSubmitted(false), 5000); // Clear success message after 5 seconds
  

        // if (response.ok) {
        //   setIsSubmitted(true);
        //   setFormData({
        //     name: '',
        //     email: '',
        //     subject: '',
        //     message: '',
        //   });
        //   setTimeout(() => setIsSubmitted(false), 5000); // Clear success message after 5 seconds
        // } else {
        //   console.error('Failed to send email');
          // Handle errors appropriately in the UI
        // }
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <div className="showcase-container">
      {/* Hero Section */}
      <section className="hero-section">
        <h1>Custom Web & App Development Services</h1>
        <p>
          At Next Leap Tech, we specialize in Web & App Development that empowers businesses to thrive in the digital landscape. Our expert team combines creativity with technical expertise to deliver solutions tailored to your unique needs.
        </p>
      </section>
      <div className="about-us-section">
        <h2>Why Choose Us? <FcBusinessman /></h2>
        <p>
          Our team of skilled developers and designers is committed to exceeding your expectations. We utilize the latest technology and industry best practices to ensure that your digital presence is optimized for performance and search engine visibility.
        </p>
      </div>

      {/* Contact Form Section */}
      <div className="flex justify-end items-center min-h-screen">
        <div className="py-12 px-4 sm:px-6 lg:px-8 max-w-md w-full rounded-lg shadow-md">
          <h2 className="text-3xl font-bold text-center mb-8">Contact Us</h2>
          <form
            onSubmit={handleSubmit}
            className="max-w-lg mx-auto space-y-6 bg-white p-8 rounded-lg shadow-lg"
            noValidate
          >
            <div className="relative">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Name
              </label>
              <input
                type="text"
                name="name"
                id="name"
                value={formData.name}
                onChange={handleChange}
                className={`mt-1 p-3 block w-full border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400 ${
                  formErrors.name ? 'border-red-500' : 'border-gray-300'
                }`}
                aria-invalid={!!formErrors.name}
                aria-describedby="name-error"
              />
              {formErrors.name && (
                <p id="name-error" className="text-red-500 text-sm mt-1">
                  {formErrors.name}
                </p>
              )}
            </div>

            <div className="relative">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <input
                type="email"
                name="email"
                id="email"
                value={formData.email}
                onChange={handleChange}
                className={`mt-1 p-3 block w-full border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400 ${
                  formErrors.email ? 'border-red-500' : 'border-gray-300'
                }`}
                aria-invalid={!!formErrors.email}
                aria-describedby="email-error"
              />
              {formErrors.email && (
                <p id="email-error" className="text-red-500 text-sm mt-1">
                  {formErrors.email}
                </p>
              )}
            </div>

            <div className="relative">
              <label
                htmlFor="subject"
                className="block text-sm font-medium text-gray-700"
              >
                Organization Name
              </label>
              <input
                type="text"
                name="subject"
                id="subject"
                value={formData.subject}
                onChange={handleChange}
                className={`mt-1 p-3 block w-full border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400 ${
                  formErrors.subject ? 'border-red-500' : 'border-gray-300'
                }`}
                aria-invalid={!!formErrors.subject}
                aria-describedby="subject-error"
              />
              {formErrors.subject && (
                <p id="subject-error" className="text-red-500 text-sm mt-1">
                  {formErrors.subject}
                </p>
              )}
            </div>

            <div className="relative">
              <label
                htmlFor="message"
                className="block text-sm font-medium text-gray-700"
              >
                Your requirements / Message
              </label>
              <textarea
                name="message"
                id="message"
                rows="4"
                value={formData.message}
                onChange={handleChange}
                className={`mt-1 p-3 block w-full border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400 ${
                  formErrors.message ? 'border-red-500' : 'border-gray-300'
                }`}
                aria-invalid={!!formErrors.message}
                aria-describedby="message-error"
              />
              {formErrors.message && (
                <p id="message-error" className="text-red-500 text-sm mt-1">
                  {formErrors.message}
                </p>
              )}
            </div>

            <div>
              <button
                type="submit"
                className="w-full px-4 py-2 bg-blue-500 text-white rounded-lg shadow-lg hover:bg-blue-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Sending...' : 'Send'}
              </button>
            </div>

            <motion.div
              className="text-green-500 text-center mt-4"
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: isSubmitted ? 1 : 0, y: isSubmitted ? 0 : 10 }}
              transition={{ duration: 0.3 }}
            >
              {isSubmitted && 'Thank you! Your message has been sent. We Will Reach You Soon.....'}
            </motion.div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
