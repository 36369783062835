import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Blog = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get('http://localhost:1337/api/blog-posts'); // Adjust the endpoint if necessary
        console.log(response.data); // Log the entire response for debugging
        setPosts(response.data.data); // Set the posts array from the response
      } catch (error) {
        console.error("Error fetching blog posts:", error);
      }
    };
    fetchPosts();
  }, []);

  return (
    <div>
      <h1>Blog</h1>
      {posts.length > 0 ? (
        posts.map((post) => (
          <div key={post.id} style={{ marginBottom: '20px', border: '1px solid #ccc', padding: '15px' }}>
            <h2>{post.blog}</h2> {/* Accessing the title directly from post.blog */}

            {/* Render the content */}
            {post.Content && post.Content.map((contentBlock, index) => (
              contentBlock.children.map((child, childIndex) => (
                <p key={childIndex}>{child.text}</p> // Render the text inside paragraphs
              ))
            ))}

            {/* Render the image */}
            {post.medianextleap && post.medianextleap.data && post.medianextleap.data.attributes && (
              <img 
                src={`http://localhost:1337${post.medianextleap.data.attributes.url}`} 
                alt={post.blog} 
                style={{ width: '100%', height: 'auto', marginTop: '10px' }} // Adjust styling as needed
              />
            )}
          </div>
        ))
      ) : (
        <p>No blog posts found.</p>
      )}
    </div>
  );
};

export default Blog;
