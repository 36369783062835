import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import '../pages/WebAppDevelopmentShowcase.css';

const Services = () => {
  const navigate = useNavigate();

  const services = [
    {
      img: 'https://images.unsplash.com/photo-1498050108023-c5249f4df085?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDd8fGRldmVsb3BtZW50fGVufDB8fHx8MTY4MTM0MTY2&ixlib=rb-1.2.1&q=80&w=1080',
      title: 'Web & App Development',
      shortDescription: 'Our expert team delivers responsive and user-friendly websites and mobile applications designed to enhance user experience.',
      keyFeatures: [
        'Custom Design: Tailored to reflect your brand’s unique identity.',
        'Mobile Responsiveness: Optimized for seamless use across all devices.',
        'SEO-Friendly Architecture: Designed for improved visibility on search engines.',
        'Fast Loading Times: Optimized for speed to reduce bounce rates.',
        'User Testing: Extensive testing to ensure functionality and usability.',
        'Content Management System (CMS) Integration: Easy updates and management of website content.',
        'Ongoing Support and Maintenance: Continuous support post-launch to ensure smooth operation.'
      ],
      path: '/web-app-development'
    },
    {
      img: '/images/desk/SAAS_1.jpeg',
      title: 'SaaS Services',
      shortDescription: 'Our Software as a Service (SaaS) solutions are designed to simplify your business processes.',
      keyFeatures: [
        'Cloud-Based Solutions: Access your applications from anywhere, anytime.',
        'Integration with Existing Systems: Ensure smooth data flow between applications.',
        'Ongoing Support and Maintenance: Continuous assistance for technical issues.',
        'Customizable Features: Tailor functionalities to meet specific business requirements.',
        'User Analytics: Monitor user behavior to inform future improvements.',
        'Automated Updates: Regular updates without downtime for users.',
        'High Availability and Scalability: Designed to accommodate growing user bases without sacrificing performance.'
      ],
      path: '/SaasPage'
    },
    {
      img: '/images/desk/Data&eng_3.jpeg',
      title: 'Data Engineering & Solutions',
      shortDescription: 'Transform raw data into actionable insights with our data engineering services.',
      keyFeatures: [
        'Comprehensive Data Analysis: Uncover trends and patterns from your data.',
        'Custom Dashboards: Visualize data in real-time tailored to your KPIs.',
        'Data Security and Compliance: Ensure your data is secure and meets regulatory standards.',
        'Data Warehousing Solutions: Efficient storage and retrieval of large datasets.',
        'Real-Time Data Processing: Make immediate decisions based on live data.',
        'Data Migration Services: Smooth transition of data between systems.',
        'Predictive Analytics: Use advanced algorithms to forecast future trends and behaviors.'
      ],
      path: '/data-engineering'
    },
    {
      img: '/images/desk/ecommerce_5.jpeg',
      title: 'E-commerce Solutions',
      shortDescription: 'A fully-featured online store with a sleek design.',
      keyFeatures: [
        'User-Friendly Shopping Experience: Simplified navigation and checkout processes.',
        'Secure Payment Gateways: Protect sensitive transaction information.',
        'Inventory Management Tools: Track stock levels and manage orders effectively.',
        'Multichannel Selling: Expand your reach through integration with various platforms.',
        'Abandoned Cart Recovery: Implement strategies to recover lost sales.',
        'Customer Reviews and Ratings: Enhance trust through social proof.',
        'Analytics Integration: Track sales performance and customer behavior to optimize strategies.'
      ],
      path: '/ecommerce-store'
    }
  ];

  const [expandedStates, setExpandedStates] = useState(Array(services.length).fill(false));

  const handleCardClick = (event, path, isExpanded) => {
    if (!isExpanded) {
      navigate(path);
    }
  };

  const handleButtonClick = (event, index) => {
    event.stopPropagation();
    setExpandedStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = !newStates[index];
      return newStates;
    });
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
  };

  return (
    <div className="p-8 md:px-16 lg:px-24" style={{ background: 'radial-gradient(circle, #d3d3d3 30%, #a2b9bc 70%)' }}>
      <h2 className="text-3xl font-bold mb-4 text-center">Our Services</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {services.map((service, index) => (
          <motion.div
            key={index}
            className="bg-gray-100 p-6 rounded-lg shadow-lg transition-all duration-300 ease-in-out"
            variants={cardVariants}
            initial="hidden"
            animate="visible"
            whileHover={{ scale: 1.05, rotate: 2 }}
            whileTap={{ scale: 0.95 }}
            onClick={(event) => handleCardClick(event, service.path, expandedStates[index])}
            style={{ height: expandedStates[index] ? 'auto' : '400px' }} // Set a base height when not expanded
          >
            <motion.img
              src={service.img}
              alt={service.title}
              className="w-full h-32 object-cover rounded-t-lg"
              initial={{ y: -20 }}
              animate={{ y: 0 }}
              transition={{ duration: 0.5 }}
            />
            <h3 className="text-xl font-semibold mt-4">{service.title}</h3>
            <p className="mt-2 mb-4">{service.shortDescription}</p>

            <div id={`service-content-${index}`} style={{ display: expandedStates[index] ? 'block' : 'none' }}>
              {expandedStates[index] && (
                <div className="mt-4">
                  <h4 className="font-semibold mb-2">Key Features:</h4>
                  <ul className="list-disc pl-5">
                    {service.keyFeatures.map((feature, idx) => (
                      <li key={idx} className="mb-1">{feature}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>

            <button
              className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 focus:outline-none"
              onClick={(event) => handleButtonClick(event, index)}
              aria-expanded={expandedStates[index]}
              aria-controls={`service-content-${index}`}
            >
              {expandedStates[index] ? 'Show Less Features' : 'View More Features'}
            </button>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Services;
