import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaBusinessTime } from "react-icons/fa6";
import { FcBusinessman } from "react-icons/fc";
import './WebAppDevelopmentShowcase.css';
// import './SaaSPage.css';

const InfluencerMarketing = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/consultation'); // Assuming you want to navigate to a consultation page
  };

  return (
    <div className="showcase-container">
      {/* Meta Description for SEO */}
      <meta name="description" content="Expand your reach through our Influencer Marketing services. Contact us today for an influencer strategy consultation!" />
      
      {/* Hero Section */}
      <div className="hero-section">
        <h1>Influencer Marketing - Leverage Influencer Partnerships</h1>
        <p>
          At Next Leap Tech, we connect brands directly with influencers and resonate with target audiences creating authentic promotional opportunities leading to increased visibility and sales conversions.
        </p>
        <button className="cta-button" onClick={handleButtonClick}>
          <FaBusinessTime /> Get Your Free Consultation
        </button>
      </div>

      {/* Services Section */}
      <div className="services-section">
        <h2 className="text-3xl font-bold mb-4 grid place-items-center bg-gray-100">Our SMM Services Include:</h2>
        <div className="services-grid">
          <div className="service-card left">
            <img src="images/desk/influ_3.jpeg" alt="Campaign Strategy Development" />
            <div className="service-card-text">
              <h3 className="text-1xl font-bold mb-1">Campaign Strategy Development</h3>
              <p>
                Crafting comprehensive strategies aligning brand goals and influencer partnerships maximizing impact.
              </p>
            </div>
          </div>

          <div className="service-card right">
            <img className='mobile_view' src="images/desk/influ_4.jpeg" alt="Performance Tracking" />
            <div className="service-card-text">
              <h3 className="text-1xl font-bold mb-1">Performance Tracking Reporting</h3>
              <p>
                Regular updates detailing campaign effectiveness allowing adjustments based on real-time feedback received.
              </p>
            </div>
            <img className='desktop_view' src="images/desk/influ_4.jpeg" alt="Performance Tracking" />
          </div>
        </div>
      </div>

      {/* Why Choose Us Section */}
      <div className="about-us-section">
      <h2>Why Choose Us?   <FcBusinessman /></h2>
        <p>
          <strong>Proven Success Rate:</strong> Successful collaborations resulting in increased brand awareness and trust among consumers.
        </p>
        <p>
          <strong>Tailored Approaches:</strong> Each campaign is customized according to specific objectives ensuring alignment throughout the process.
        </p>
        <p>
          <strong>Expert Support:</strong> Our dedicated team is here to assist you at every step of the way, providing ongoing support and maintenance as needed.
        </p>
      </div>

      {/* Client Success Stories */}
      <div className="about-us-section">
        <h2 className="text-1xl font-bold mb-1">Client Success Stories</h2>
        <p>
          "Partnering with influencers recommended by Next Leap Tech has taken our brand visibility up several notches!"
          <br />— Chandan, Ashraya Solutions
        </p>
      </div>

      {/* CTA Section */}
      <div className="cta-section">
        <h2 className="text-1xl font-bold mb-1">Get Started Today!</h2>
        <p>
          Expand reach by leveraging influencer marketing strategies! <a href="/contact" className="cta-link">Contact us today</a> to discuss how we can help you achieve your business goals with our experts.
        </p>
      </div>
    </div>
  );
};

export default InfluencerMarketing;
