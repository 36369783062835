import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaBusinessTime } from "react-icons/fa6";
import { FcBusinessman } from "react-icons/fc";
import './WebAppDevelopmentShowcase.css';
// import './SaaSPage.css';

const ContentMarketing = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  const handleButtonClick = () => {
    navigate('/contact'); // Navigate to the contact page
  };

  return (
    
    <div className="showcase-container">
      {/* Meta Description for SEO */}
      <meta name="description" content="Drive engagement with our effective content marketing strategies. Contact us today for a content strategy consultation!" />
      
      {/* Hero Section */}
      <div className="hero-section">
        <h1>Content Marketing - Engage Your Audience with Quality Content</h1>
        <p>
          At Next Leap Tech, we specialize in creating high-quality content that resonates with your audience while enhancing brand visibility through effective content marketing strategies tailored specifically for businesses like yours.
        </p><br>
        </br>
        <button className="cta-button" onClick={handleButtonClick}><FaBusinessTime />Get Your Free Consultation </button> {/* Updated button */}
       
      </div>

      {/* Services Section */}
      <div className="services-section">
      
        <h2 className="text-3xl font-bold mb-4 grid place-items-center  bg-gray-100">Our Content Marketing Services Include:</h2>
        <div className="services-grid">
          <div className="service-card left">
            <img src="images/desk/cmm_2.jpeg" alt="Blog Writing" />
            <div className="service-card-text">
              <h3 className="text-1xl font-bold mb-1">Blog Writing</h3><br></br>
              <p>
                Regularly updated blog posts are designed not only to inform but also to engage readers while optimizing for relevant keywords.
              </p>
            </div>
          </div>

          <div className="service-card right">
          <img className='mobile_view' src="images/desk/cmm_4.jpeg" alt="Infographics Creation" />
            <div className="service-card-text">
              <h3 className="text-1xl font-bold mb-1">Infographics Creation</h3><br></br>
              <p>
                Visually appealing infographics that simplify complex information while encouraging shares across social media platforms.
              </p>
            </div>
            <img className='desktop_view' src="images/desk/cmm_4.jpeg" alt="Infographics Creation" />
          </div>

          <div className="service-card left">
            <img src="images/desk/cmm_5.jpeg" alt="Video Content Production" />
            <div className="service-card-text">
              <h3 className="text-1xl font-bold mb-1">Video Content Production</h3><br></br>
              <p>
                Engaging video content designed for various platforms including YouTube, social media channels or embedded within blog posts.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Why Choose Us Section */}
      <div className="about-us-section">
      <h2>Why Choose Us?   <FcBusinessman /></h2>
        <p>
        <strong>Tailored Strategies:</strong> Each content strategy is customized according to specific goals—whether it’s driving traffic or building brand authority.
        </p>
        <p>
        <strong>Expert Writers:</strong> Our team consists of skilled writers experienced in various industries who know how best to communicate messages effectively.
        </p>
        <p>
        <strong>Analytics Driven:</strong> We monitor performance metrics closely, allowing us to adjust strategies based on what resonates most with audiences.
        </p>
      </div>

      <div className="about-us-section">
        <h2 className="text-1xl font-bold mb-1">Client Success Stories</h2><br></br>
        <p>"Working with Next Leap Tech has transformed how we approach content marketing! Their expertise shines through every piece they create."<br></br>
            — Chandan  , Ashraya Solutions
        </p>
        </div>

      {/* CTA Section */}
      <div className="cta-section">
        <h2 className="text-1xl font-bold mb-1">Get Started Today!</h2><br></br>
        <p>
            Drive engagement through quality content! <a href="/contact" className="cta-link">Contact us today</a> to discuss how we can help you achieve your business goals with our experts.
        </p>
      </div>
    </div>
  );
};

export default ContentMarketing;
