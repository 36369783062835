// PrivacyPolicyPage.js
import React from 'react';
import { FaUserSecret, FaInfoCircle, FaShareAlt, FaLock, FaUserCheck, FaRegClock } from 'react-icons/fa';

const PrivacyPolicyPage = () => {
  return (
    <div style={{
      maxWidth: '900px',
      margin: 'auto',
      padding: '60px 30px',
      fontFamily: "'Roboto', sans-serif",
      lineHeight: '1.8',
      color: '#2d3436',
      backgroundColor: '#f5f5f5',
      boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
      borderRadius: '8px'
    }}>
      <h1 style={{
        fontSize: '40px',
        fontWeight: '700',
        textAlign: 'center',
        marginBottom: '20px',
        color: '#0984e3',
        textShadow: '1px 1px 1px rgba(0,0,0,0.1)'
      }}>
        Privacy Policy
      </h1>
      
      <p style={{ fontSize: '18px', textAlign: 'center', color: '#636e72' }}>
        Your privacy is of utmost importance to us. This document outlines what information we collect, why, and how we ensure your data stays secure.
      </p>

      <div style={{ marginTop: '40px' }}>
        <h2 style={{ fontSize: '24px', color: '#6c5ce7', display: 'flex', alignItems: 'center' }}>
          <FaUserSecret style={{ marginRight: '10px' }} /> 1. Information We Collect
        </h2>
        <p>We collect different types of information to improve your experience, including:</p>
        <ul style={{ paddingLeft: '20px', color: '#636e72' }}>
          <li>**Personal Information**: Such as name, email address, and phone number.</li>
          <li>**Usage Data**: Includes IP addresses, browser type, and interaction data.</li>
          <li>**Cookies**: To optimize your experience and improve site functionality.</li>
        </ul>
      </div>

      <div style={{ marginTop: '30px' }}>
        <h2 style={{ fontSize: '24px', color: '#6c5ce7', display: 'flex', alignItems: 'center' }}>
          <FaInfoCircle style={{ marginRight: '10px' }} /> 2. How We Use Your Information
        </h2>
        <p>We use collected information for the following purposes:</p>
        <ul style={{ paddingLeft: '20px', color: '#636e72' }}>
          <li>Personalizing your experience and enhancing our services.</li>
          <li>Communicating updates, offers, or necessary service information.</li>
          <li>Analyzing trends and usage patterns to improve our site.</li>
        </ul>
      </div>

      <div style={{ marginTop: '30px' }}>
        <h2 style={{ fontSize: '24px', color: '#6c5ce7', display: 'flex', alignItems: 'center' }}>
          <FaShareAlt style={{ marginRight: '10px' }} /> 3. Information Sharing and Disclosure
        </h2>
        <p>We maintain strict confidentiality and only share information in cases such as:</p>
        <ul style={{ paddingLeft: '20px', color: '#636e72' }}>
          <li>**Service Providers**: To assist in service delivery.</li>
          <li>**Legal Requirements**: If required to comply with legal obligations.</li>
        </ul>
      </div>

      <div style={{ marginTop: '30px' }}>
        <h2 style={{ fontSize: '24px', color: '#6c5ce7', display: 'flex', alignItems: 'center' }}>
          <FaLock style={{ marginRight: '10px' }} /> 4. Data Security
        </h2>
        <p>We implement robust security measures to safeguard your data. However, no method of internet transmission is entirely secure.</p>
      </div>

      <div style={{ marginTop: '30px' }}>
        <h2 style={{ fontSize: '24px', color: '#6c5ce7', display: 'flex', alignItems: 'center' }}>
          <FaUserCheck style={{ marginRight: '10px' }} /> 5. Your Consent
        </h2>
        <p>By using our site, you consent to this privacy policy and its practices.</p>
      </div>

      <div style={{ marginTop: '30px' }}>
        <h2 style={{ fontSize: '24px', color: '#6c5ce7', display: 'flex', alignItems: 'center' }}>
          <FaRegClock style={{ marginRight: '10px' }} /> 6. Changes to Our Privacy Policy
        </h2>
        <p>We reserve the right to update this policy as needed. We will notify users by posting updates on this page.</p>
      </div>

      <p style={{ fontSize: '16px', textAlign: 'center', marginTop: '50px', color: '#b2bec3' }}>Last updated: 28-10-2024 Monday 12:00pm(IST)</p>
    </div>
  );
};

export default PrivacyPolicyPage;
