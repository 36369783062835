import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaBusinessTime } from "react-icons/fa6";
import { FcBusinessman } from "react-icons/fc";
import './WebAppDevelopmentShowcase.css';
// import './SaaSPage.css';


const Contentmarketing = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  const handleButtonClick = () => {
    navigate('/contact'); // Navigate to the contact page
  };

  return (
    
    <div className="showcase-container">
      {/* Meta Description for SEO */}
      <meta name="description" content="Develop a strong brand strategy with our expert services. Contact us today for a consultation!" />
      
      {/* Hero Section */}
      <div className="hero-section">
        <h1>Brand Strategy Development - Develop a Strong Brand Identity</h1>
        <p>
          At Next Leap Tech, we understand that a well-defined brand strategy is essential for standing out in today’s competitive marketplace. Our Brand Strategy Development services are designed to help businesses articulate their identity, connect with their target audience, and build lasting relationships that drive loyalty and sales.
        </p><br>
        </br>
        <button className="cta-button" onClick={handleButtonClick}><FaBusinessTime />Get Your Free Consultation </button> {/* Updated button */}
       
      </div>

<div className="about-us-section">
        <h2 className="text-1xl font-bold mb-1">What is Brand Strategy Development?</h2><br></br>
        <p>Brand strategy development is the process of creating a comprehensive plan that outlines how your brand will communicate its values, vision, and mission to the world. This strategy encompasses various elements, including brand positioning, messaging, visual identity, and customer engagement tactics. A strong brand strategy not only differentiates your business from competitors but also fosters trust and recognition among consumers.
        </p>
</div>
      {/* Services Section */}
      <div className="services-section">
      
        <h2 className="text-3xl font-bold mb-4 grid place-items-center  bg-gray-100">Our Brand Strategy Development Services Include:</h2>
        <div className="services-grid">
          <div className="service-card left">
            <img src="images/desk/b&d_2.jpeg" alt="Brand Positioning" />
            <div className="service-card-text">
              <h3 className="text-1xl font-bold mb-1">Brand Positioning</h3><br></br>
              <p>
                We help you define where your brand fits within the market landscape. By analyzing competitors and understanding your unique value proposition, we position your brand effectively to attract your target audience. This involves identifying key differentiators that make your brand stand out.
              </p>
            </div>
          </div>

          <div className="service-card right">
          <img className='mobile_view' src="images/desk/b&d_4.jpeg" alt="Messaging Frameworks" />
            <div className="service-card-text">
              <h3 className="text-1xl font-bold mb-1">Messaging Frameworks</h3><br></br>
              <p>
                Crafting clear and compelling messaging is vital for effective communication. We develop messaging frameworks that articulate your brand's voice, tone, and key messages, ensuring consistency across all platforms. This includes creating taglines and slogans that resonate with your audience.
              </p>
            </div>
            <img className='desktop_view' src="images/desk/b&d_4.jpeg" alt="Messaging Frameworks" />
          </div>
         
          <div className="service-card left">
            <img src="images/desk/b&d_5.jpeg" alt="Visual Identity Creation" />
            <div className="service-card-text">
              <h3 className="text-1xl font-bold mb-1">Visual Identity Creation</h3><br></br>
              <p>
                Your brand's visual identity is crucial for recognition and recall. Our team designs logos, color palettes, typography, and other visual elements that reflect your brand's personality and resonate with your audience. A cohesive visual identity enhances brand recall and strengthens customer loyalty.
              </p>
            </div>
          </div>

          <div className="service-card right">
          <img className='mobile_view' src="images/desk/b&d_6.jpeg" alt="Market Research Development" />
            <div className="service-card-text">
              <h3 className="text-1xl font-bold mb-1">Market Research & Audience Analysis</h3><br></br>
              <p>
                Understanding your audience is key to effective branding. We conduct thorough market research to identify target demographics, preferences, and behaviors, allowing us to tailor strategies that speak directly to potential customers. This research informs all aspects of your branding strategy.
              </p>
            </div>
          </div>    
  
          <div className="service-card left">
            <img src="images/desk/b&d_11.jpeg" alt="Custom Web Development" />
            <div className="service-card-text">
              <h3 className="text-1xl font-bold mb-1">Brand Storytelling</h3><br></br>
              <p>
                Every brand has a story to tell. We help you craft a compelling narrative that communicates your brand's history, values, and mission in a way that engages customers emotionally. Effective storytelling can significantly enhance brand loyalty and customer engagement.
              </p>
            </div>
          </div>

          <div className="service-card right">
          <img className='mobile_view' src="https://picsum.photos/500/300?random=2" alt="Mobile App Development" />
            <div className="service-card-text">
              <h3 className="text-1xl font-bold mb-1">Implementation & Guidelines</h3><br></br>
              <p>
                Once your brand strategy is developed, we provide implementation support and create comprehensive brand guidelines to ensure consistency in all marketing efforts. These guidelines serve as a reference for anyone involved in communicating or representing the brand.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Why Choose Us Section */}
      <div className="about-us-section">
      <h2>Why Choose Us?   <FcBusinessman /></h2>
        <p>
        <strong>Expertise in Brand Development:</strong> Our team consists of seasoned branding professionals with extensive experience across various industries. We understand the nuances of effective branding strategies.
        </p>
        <p>
        <strong>Analytics-Driven Approach:</strong> We recognize that every business is unique; therefore, we customize our approach based on your specific goals and market conditions.
        </p>
        <p>
        <strong>Data-Driven Insights:</strong> Our strategies are grounded in data and research. We leverage analytics to inform our decisions and measure the effectiveness of branding efforts over time.
        </p>
        <p>
        <strong>Ongoing Support:</strong> Branding is an ongoing process. We offer continuous support to adapt your strategy as market dynamics change or as your business evolves.
        </p>
      </div>
      
      <div className="about-us-section">
        <h2 className="text-1xl font-bold mb-1">The Importance of a Strong Brand Strategy</h2><br></br>
        <p>
          A robust brand strategy serves as the foundation for all marketing efforts. It ensures that every piece of content—whether it’s an advertisement, social media post, or website copy—aligns with your overall vision and messaging. This consistency builds trust with consumers and enhances brand loyalty over time.
        </p>
      </div>

      <div className="about-us-section">
        <h2 className="text-1xl font-bold mb-1">Key Benefits of Effective Brand Strategy Development</h2><br></br>
        <p>
          <ul>
            <li><strong>Increased Brand Recognition:</strong> A strong brand identity makes it easier for consumers to identify and remember your products or services.</li>
            <li><strong>Enhanced Customer Loyalty:</strong> When customers resonate with your brand values and messaging, they are more likely to return for repeat purchases.</li>
            <li><strong>Competitive Advantage:</strong> A well-defined brand strategy sets you apart from competitors by clearly communicating what makes you unique in the marketplace.</li>
        </ul>
        </p>
      </div>
        
      <div className="about-us-section">
        <h2 className="text-1xl font-bold mb-1">Client Success Stories</h2><br></br>
        <p>"Working with Next Leap Tech transformed our approach to branding! Their expertise helped us clarify our message and connect with our audience on a deeper level."<br></br>
            — Chandan, Ashraya Solutions
        </p>
        </div>

      {/* CTA Section */}
      <div className="cta-section">
        <h2 className="text-1xl font-bold mb-1">Get Started Today!</h2><br></br>
        <p>
            Are you ready to build a powerful brand strategy that drives growth? <a href="/contact" className="cta-link">Contact us today</a> to discuss how we can help you achieve your business goals with our experts.
        </p>
      </div>
    </div>
  );
};

export default Contentmarketing;
