import React from 'react';
import { useNavigate } from 'react-router-dom';
import './WebAppDevelopmentShowcase.css';

const MaintenancenSupport = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  const handleButtonClick = () => {
    navigate('/contact'); // Navigate to the contact page
  };

  return (
    <div className="showcase-container">
      {/* Meta Description for SEO */}
      <meta name="description" content="Custom web and app development services tailored to enhance your business performance. Discover our expertise in creating responsive websites, mobile apps, e-commerce solutions, and more. Contact us for a consultation!" />
      
      {/* Hero Section */}
      <div className="hero-section">
        <h1>Transform Your Digital Presence with Expert Web & App Development</h1>
        <p>
          Our custom solutions are designed to boost your business with high-performance web and mobile applications. From responsive websites to scalable e-commerce platforms, we deliver results that drive growth and engagement.
        </p><br>
        </br>
        <button className="cta-button" onClick={handleButtonClick}>Get Your Free Consultation</button> {/* Updated button */}
      </div>

      {/* Services Section */}
      <div className="services-section">
      
        <h1 className="text-3xl font-bold mb-4 grid place-items-center  bg-gray-100">Our Expertise</h1>
        <div className="services-grid">
          <div className="service-card left">
            <img src="https://picsum.photos/500/300?random=1" alt="Custom Web Development" />
            <div className="service-card-text">
              <h2 className="text-1xl font-bold mb-1">Custom Web Development</h2><br></br>
              <p>
                We build responsive and SEO-friendly websites using cutting-edge technologies like React, Angular, and Vue.js. Enhance your online presence with user-friendly and visually appealing web solutions.
              </p>
            </div>
          </div>

          <div className="service-card right">
          <img className='mobile_view' src="https://picsum.photos/500/300?random=2" alt="Mobile App Development" />
            <div className="service-card-text">
              <h3>Mobile App Development</h3>
              <p>
                Our mobile applications for iOS and Android are designed for seamless functionality and exceptional user experience, aligned with your business goals.
              </p>
            </div>
            <img className='desktop_view' src="https://picsum.photos/500/300?random=2" alt="Mobile App Development" />
          </div>

          <div className="service-card left">
            <img src="https://picsum.photos/500/300?random=3" alt="E-Commerce Solutions" />
            <div className="service-card-text">
              <h3>E-Commerce Solutions</h3>
              <p>
                Create a powerful online store with our scalable e-commerce platforms. We integrate payment gateways and enhance user experiences to drive sales.
              </p>
            </div>
          </div>

          <div className="service-card right">
          <img className='mobile_view' src="https://picsum.photos/500/300?random=4" alt="API Integration" />
            <div className="service-card-text">
              <h3>API Integration</h3>
              <p>
                Enhance your applications by connecting with third-party services for improved functionality and streamlined operations.
              </p>
            </div>
            <img className='desktop_view' src="https://picsum.photos/500/300?random=4" alt="API Integration" />
          </div>

          <div className="service-card left">
            <img src="https://picsum.photos/500/300?random=5" alt="Maintenance and Support" />
            <div className="service-card-text">
              <h3>Maintenance and Support</h3>
              <p>
                Keep your web and app solutions updated and running smoothly with our ongoing support and maintenance services.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Why Choose Us Section */}
      <div className="about-us-section">
        <h2>Why Choose Us?</h2>
        <p>
          Our team of skilled developers and designers is committed to exceeding your expectations. We utilize the latest technology and industry best practices to ensure that your digital presence is optimized for performance and search engine visibility.
        </p>
      </div>

      {/* CTA Section */}
      <div className="cta-section">
        <p>
          Ready to elevate your digital strategy? <a href="/contact" className="cta-link">Contact us today</a> to discuss how we can help you achieve your business goals with our expert web and app development services.
        </p>
      </div>
    </div>
  );
};

export default MaintenancenSupport;
