import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaBusinessTime } from "react-icons/fa6";
import { FcBusinessman } from "react-icons/fc";
// import './WebAppDevelopmentShowcase.css';
import './SaaSPage.css';


const Emailmarketing = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  const handleButtonClick = () => {
    navigate('/contact'); // Navigate to the contact page
  };

  return (
    
    <div className="showcase-container">
      {/* Meta Description for SEO */}
      <meta name="description" content="Engage customers with our Email Marketing services. Contact us today for an email marketing strategy consultation!" />
      
      {/* Hero Section */}
      <div className="hero-section">
        <h1>Email Marketing - Nurture Leads Through Effective Email Campaigns</h1>
        <p>
          At Next Leap Tech, we create personalized email marketing campaigns designed nurture leads retain customers over time—ensuring long-lasting relationships built trust.
        </p><br>
        </br>
        <button className="cta-button" onClick={handleButtonClick}><FaBusinessTime />Get Your Free Consultation </button> {/* Updated button */}
       
      </div>

      {/* Services Section */}
      <div className="services-section">
      
        <h2 className="text-3xl font-bold mb-4 grid place-items-center  bg-gray-100">Our Email Marketing Services Include:</h2>
        <div className="services-grid">
          <div className="service-card left">
            <img src="images/desk/email_m_2.jpeg" alt="Custom Web Development" />
            <div className="service-card-text">
              <h3 className="text-1xl font-bold mb-1">Newsletter Creation</h3><br></br>
              <p>
                Engaging newsletters featuring updates promotions relevant industry news sent directly to subscribers inboxes.
              </p>
            </div>
          </div>

          <div className="service-card right">
          <img className='mobile_view' src="images/desk/email_m_3.jpeg" alt="Mobile App Development" />
            <div className="service-card-text">
              <h3 className="text-1xl font-bold mb-1">Automated Campaigns</h3><br></br>
              <p>
                Set up automated workflows based on user behavior allowing timely follow-ups without manual intervention.
              </p>
            </div>
            <img className='desktop_view' src="images/desk/email_m_3.jpeg" alt="Mobile App Development" />
          </div>
        </div>
      </div>

      {/* Why Choose Us Section */}
      <div className="about-us-section">
      <h2>Why Choose Us?   <FcBusinessman /></h2>
        <p>
        <strong>High Open Rates:</strong> Utilizing best practices ensures emails reach intended recipients’ inboxes rather than spam folders!
        </p>
        <p>
        <strong>Analytics-Driven Approach:</strong> Regular tracking metrics such as open click-through rates help refine future campaigns based on feedback received.
        </p>
        <p>
        <strong>Expert Support:</strong> Our dedicated team is here to assist you at every step of the way, providing ongoing support and maintenance as needed.
        </p>
      </div>

      <div className="about-us-section">
        <h2 className="text-1xl font-bold mb-1">Client Success Stories</h2><br></br>
        <p>"The email campaigns crafted by Next Leap Tech have significantly improved engagement rates among our subscribers!"<br></br>
            — Chandan, Ashraya Solutions
        </p>
        </div>

      {/* CTA Section */}
      <div className="cta-section">
        <h2 className="text-1xl font-bold mb-1">Get Started Today!</h2><br></br>
        <p>
            Nurture leads using effective email marketing strategies! <a href="/contact" className="cta-link">Contact us today</a> to discuss how we can help you achieve your business goals with our experts.
        </p>
      </div>
    </div>
  );
};

export default Emailmarketing;
