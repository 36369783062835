import React from 'react';
import Banner from '../components/Banner';
import Services from '../components/Services';
import Portfolio from '../components/Portfolio';
import Features from '../components/Features';
import Testimonials from '../components/Testimonials';
// import Header from '../components/Header';
// import ContactForm from '../components/ContactForm';

const HomePage = () => {
  return (
    <div>
      {/* <Header /> */}

      {/* Banner Section */}
      <Banner />

      {/* Main Content Anchor */}
      <div id="main-content">
        {/* Services Section */}
        <Services />

        {/* Portfolio Section */}
        <Portfolio />

        {/* Features Section */}
        <Features />

        {/* Testimonials Section */}
        <Testimonials />

        {/* Contact Form Section
        <ContactForm /> */}
      </div>

      
    </div>
  );
};

export default HomePage;
