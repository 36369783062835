import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaBusinessTime } from "react-icons/fa6";
import { FcBusinessman } from "react-icons/fc";
import './WebAppDevelopmentShowcase.css';
// import './SaaSPage.css';

const Analyticsandreporting = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  const handleButtonClick = () => {
    navigate('/contact'); // Navigate to the contact page
  };

  return (
    
    <div className="showcase-container">
      {/* Meta Description for SEO */}
      <meta name="description" content="Gain insights into performance through our Analytics & Reporting services. Contact us today!" />
      
      {/* Hero Section */}
      <div className="hero-section">
        <h1>Analytics & Reporting: Make Data-driven Decisions</h1>
        <p>
          At Next Leap Tech, we provide comprehensive analytics reporting services helping businesses understand performance metrics driving informed decision-making processes.
        </p><br>
        </br>
        <button className="cta-button" onClick={handleButtonClick}><FaBusinessTime />Get Your Free Consultation </button> {/* Updated button */}
       
      </div>

      {/* Services Section */}
      <div className="services-section">
      
        <h2 className="text-3xl font-bold mb-4 grid place-items-center  bg-gray-100">Our Analytics & Reporting Services Include:</h2>
        <div className="services-grid">
          <div className="service-card left">
            <img src="images/desk/analytics_2.jpeg" alt="Performance Tracking Development" />
            <div className="service-card-text">
              <h3 className="text-1xl font-bold mb-1">Performance Tracking Metrics Analysis</h3><br></br>
              <p>
                Detailed analysis of key performance indicators allowing identification areas of improvement growth opportunities.
              </p>
            </div>
          </div>

          <div className="service-card right">
          <img className='mobile_view' src="images/desk/analytics_3.jpeg" alt="Custom Dashboards  Development" />
            <div className="service-card-text">
              <h3 className="text-1xl font-bold mb-1">Custom Dashboards Visualizations</h3><br></br>
              <p>
                Easy-to-understand dashboards providing real-time insights into critical metrics facilitating quick decision-making processes.
              </p>
            </div>
            <img className='desktop_view' src="images/desk/analytics_3.jpeg" alt="Custom Dashboards  Development" />
          </div>
        </div>
      </div>

      {/* Why Choose Us Section */}
      <div className="about-us-section">
      <h2>Why Choose Us?   <FcBusinessman /></h2>
        <p>
        <strong>Actionable Insights Provided Regularly:</strong> Regular reports detailing findings and recommendations based on collected data ensure continuous improvement efforts align with strategic objectives.
        </p>
        <p>
        <strong>Expert Team Available Support Questions Concerns:</strong> Dedicated professionals ready to assist clients in navigating complexities associated with analyzing and interpreting results gathered over time.
        </p>
        <p>
        <strong>Expert Support:</strong> Our dedicated team is here to assist you at every step of the way, providing ongoing support and maintenance as needed.
        </p>
      </div>

      <div className="about-us-section">
        <h2 className="text-1xl font-bold mb-1">Client Success Stories</h2><br></br>
        <p>"The insights we've gained from analytics provided by Next Leap Tech have transformed how we operate!"<br></br>
            — Chandan, Ashraya Solutions
        </p>
        </div>

      {/* CTA Section */}
      <div className="cta-section">
        <h2 className="text-1xl font-bold mb-1">Get Started Today!</h2><br></br>
        <p>
            Make data-driven decisions utilizing analytics reporting services offered here! <a href="/contact" className="cta-link">Contact us today</a> to discuss how we can help you achieve your business goals with our experts.
        </p>
      </div>
    </div>
  );
};

export default Analyticsandreporting;
