// Footer.js
import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  const sitemapLinks = [
    { path: "/", label: "Home" },
    { path: "/about", label: "About" },
    { path: "/contact", label: "Contact" },
    { path: "/web-app-development", label: "Web App Development" },
    { path: "/saaspage", label: "SaaS" },
    { path: "/data-engineering", label: "Data Engineering" },
    { path: "/business-brands-website", label: "Business Brands Website" },
    { path: "/ecommerce-store", label: "Ecommerce Store" },
    { path: "/seo-optimization", label: "SEO Optimization" },
    { path: "/maintenancen-support", label: "Maintenance & Support" },
    { path: "/Privacypage", label: "Privacy Policy" },
    { path: "/terms-and-conditions", label: "Terms & Conditions" },
  ];

  const numberOfColumns = 3;
  const linksPerColumn = Math.ceil(sitemapLinks.length / numberOfColumns);

  return (
    <footer className="bg-gray-800 text-white py-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-start md:items-start">
          {/* Sitemap Section */}
          <div className="mb-6 md:mb-0 w-full md:w-1/3">
            <h3 className="text-lg font-semibold mb-2 text-blue-400">Sitemap</h3>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {Array.from({ length: numberOfColumns }).map((_, colIndex) => (
                <nav key={colIndex}>
                  <ul className="flex flex-col space-y-2">
                    {sitemapLinks.slice(colIndex * linksPerColumn, (colIndex + 1) * linksPerColumn).map((link) => (
                      <li key={link.path}>
                        <Link to={link.path} className="hover:text-gray-400">{link.label}</Link>
                      </li>
                    ))}
                  </ul>
                </nav>
              ))}
            </div>
          </div>

          {/* Follow Us Section */}
          <div className="mb-6 md:mb-0 w-full md:w-1/3 md:ml-8">
            <h3 className="text-lg font-semibold mb-2 text-blue-400">Follow Us</h3>
            <div className="flex space-x-4">
              <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="hover:text-gray-400">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="hover:text-gray-400">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="hover:text-gray-400">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="hover:text-gray-400">
                <i className="fab fa-linkedin-in"></i>
              </a>
            </div>
          </div>

          {/* Contact Us Section */}
          <div className="w-full md:w-1/3 md:ml-8">
            <h3 className="text-lg font-semibold mb-2 text-blue-400">Contact Us</h3>
            <p className="mb-2">Email: <a href="mailto:nextleaptechnology@gmail.com" className="hover:text-gray-400">nextleaptechnology@gmail.com</a></p>
            <p>Phone: <a href="tel:+123456789" className="hover:text-gray-400">added soon</a></p>
          </div>
        </div>

        {/* Copyright Section */}
        <p className="mt-8 text-center">&copy; {new Date().getFullYear()} NextLeap Tech. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
