import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaBusinessTime } from "react-icons/fa6";
import { FcBusinessman } from "react-icons/fc";
// import './WebAppDevelopmentShowcase.css';
import './SaaSPage.css';



const Googleadvertising = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  const handleButtonClick = () => {
    navigate('/contact'); // Navigate to the contact page
  };

  return (
    
    <div className="showcase-container">
      {/* Meta Description for SEO */}
      <meta name="description" content="Drive targeted traffic with our Pay-Per-Click advertising services. Contact us today for a PPC strategy consultation!" />
      
      {/* Hero Section */}
      <div className="hero-section">
        <h1>Pay-Per-Click (PPC) Advertising - Maximize Your ROI with PPC</h1>
        <p>
          At Next Leap Tech, we specialize in creating effective Pay-Per-Click (PPC) advertising campaigns designed maximize ROI while minimizing costs associated running ads.
        </p><br>
        </br>
        <button className="cta-button" onClick={handleButtonClick}><FaBusinessTime />Get Your Free Consultation </button> {/* Updated button */}
       
      </div>

      {/* Services Section */}
      <div className="services-section">
      
        <h2 className="text-3xl font-bold mb-4 grid place-items-center  bg-gray-100">Our PPC Services Include:</h2>
        <div className="services-grid">
          <div className="service-card left">
            <img src="images/desk/payperclick_4.jpeg" alt="Google Ads Management" />
            <div className="service-card-text">
              <h3 className="text-1xl font-bold mb-1">Google Ads Management</h3><br></br>
              <p>
                Comprehensive management of Google Ads campaigns including keyword targeting ad copywriting etc., ensuring optimal performance across all metrics.
              </p>
            </div>
          </div>

          <div className="service-card right">
          <img className='mobile_view' src="images/desk/payperclick_5.jpeg" alt="Remarketing Strategies" />
            <div className="service-card-text">
              <h3 className="text-1xl font-bold mb-1">Remarketing Strategies</h3><br></br>
              <p>
                Re-engage visitors who didn’t convert initially by displaying targeted ads based on their previous interactions.
              </p>
            </div>
            <img className='desktop_view' src="images/desk/payperclick_5.jpeg" alt="Remarketing Strategies" />
          </div>
        </div>
      </div>

      {/* Why Choose Us Section */}
      <div className="about-us-section">
      <h2>Why Choose Us?   <FcBusinessman /></h2>
        <p>
        <strong>Data-driven Strategies:</strong> Every campaign is backed by thorough analysis allowing adjustments based performance metrics gathered over time.
        </p>
        <p>
        <strong>Transparent Reporting:</strong> Clients receive regular updates detailing progress made towards achieving goals set forth during initial consultations.
        </p>
        <p>
        <strong>Expert Support:</strong> Our dedicated team is here to assist you at every step of the way, providing ongoing support and maintenance as needed.
        </p>
      </div>

      <div className="about-us-section">
        <h2 className="text-1xl font-bold mb-1">Client Success Stories</h2><br></br>
        <p>"Thanks to the PPC campaigns managed by Next Leap Tech, we've seen significant increases in both traffic conversions!"<br></br>
            — Chandan, Ashraya Solutions
        </p>
        </div>

      {/* CTA Section */}
      <div className="cta-section">
        <h2 className="text-1xl font-bold mb-1">Get Started Today!</h2><br></br>
        <p>
            Drive targeted traffic using effective PPC advertising! <a href="/contact" className="cta-link">Contact us today</a> to discuss how we can help you achieve your business goals with our experts.
        </p>
      </div>
    </div>
  );
};

export default Googleadvertising;
