import React from 'react';
import { motion } from 'framer-motion';
import { FaCode, FaPaintBrush, FaSearch, FaUserLock} from 'react-icons/fa';

const features = [
  {
    icon: <FaCode className="text-4xl text-blue-500" />,
    title: 'Custom Development',
    description: 'Tailor-made solutions to fit your unique business needs.'
  },
  {
    icon: <FaPaintBrush className="text-4xl text-green-500" />,
    title: 'Creative Design',
    description: 'Eye-catching designs that make your brand stand out.'
  },
  {
    icon: <FaSearch className="text-4xl text-red-500" />,
    title: 'SEO Optimization',
    description: 'Enhancing your visibility on search engines to drive more traffic.'
  },
  {
    icon: <FaUserLock className="text-4xl text-red-500" />,
    title: 'DATA Vault',
    description: 'Securely Store Your DATA in your choice'
  }
];

const Features = () => {
  return (
    <div className="p-6 bg-gray-100">
      <h2 className="text-3xl font-bold mb-8 text-center">Our Features</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {features.map((feature, index) => (
          <motion.div
            key={index}
            className="bg-white p-6 rounded-lg shadow-lg text-center transform transition-transform hover:scale-105"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: index * 0.2 }}
          >
            <div className="mb-4">
              {feature.icon}
            </div>
            <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
            <p>{feature.description}</p>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Features;
