// NotFoundPage.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot } from '@fortawesome/free-solid-svg-icons'; // Robot icon

const NotFoundPage = () => {
  return (
    <div style={{
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center', 
      justifyContent: 'center', 
      height: '100vh', 
      backgroundColor: '#fafafa', // Very light gray background
      textAlign: 'center', 
      padding: '20px'
    }}>
      <FontAwesomeIcon 
        icon={faRobot} 
        style={{ 
          fontSize: '80px', 
          color: '#673ab7', 
          marginBottom: '20px',
          animation: 'bounce 1s infinite' // Add bounce animation
        }} 
      />
      <h1 style={{ 
        fontFamily: "'Arial', sans-serif", 
        fontSize: '60px', 
        fontWeight: 'bold', 
        color: '#333' 
      }}>
        404
      </h1>
      <p style={{ 
        fontSize: '24px', 
        color: '#555', 
        margin: '10px 0' 
      }}>
        Uh-oh! This page seems to have taken a detour.
      </p>
      <a href="/" style={{
        marginTop: '20px', 
        padding: '10px 25px', 
        backgroundColor: '#3f51b5', 
        color: '#fff', 
        textDecoration: 'none', 
        borderRadius: '5px', 
        fontWeight: '600'
      }}>
        Go Back Home
      </a>
    </div>
  );
};

export default NotFoundPage;
